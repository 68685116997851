export default defineAppConfig({
  saasName: 'FSBD Orange',
  saasLink: 'https://orange.fullstackbigdata.com',
  customSaas: false,
  languageStrings: {
    company: 'Empresa',
    companies: 'Empresas',
    briefing: {
      visibility: 'Si la visibilidad es oculto o público se asigna internamente a Taohirata.'
    }
  },
  savvywords: {
    customers: []
  },
  insights: {
    filterByCompany: false,
    filterByPlatform: false
  },
  analyticsLooker: '83555062-8449-4713-a3c9-65ec43bf83fa',
})